import React from "react"
import PropTypes from "prop-types"

const Header = props => (
  <header id="header" style={props.timeout ? { display: "none" } : {}}>
    <div className="logo"></div>
    <div className="content">
      <div className="inner">
        <h1>Dana Pasculescu</h1>
        <p>
          <strong>Licensed Marriage and Family Therapist #149523</strong>
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <a
            href="javascript:;"
            onClick={() => {
              props.onOpenArticle("education")
            }}
          >
            Education
          </a>
        </li>
        <li>
          <a
            href="javascript:;"
            onClick={() => {
              props.onOpenArticle("about")
            }}
          >
            About
          </a>
        </li>
        <li>
          <a href="https://estestherapy.janeapp.com/locations/mission-valley-office/book#/staff_member/10" target="_blank">
            Appointments
          </a>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
